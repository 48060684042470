<template>
  <div class="game" :key="quote.text">
    <div class="quote">
      <div class="animate__animated animate__fadeIn">{{ $t("who") }}</div>
      <div class="animate__animated animate__fadeIn">
        ❝ {{ quoteTranslated.text }} ❞
      </div>
      <div
        v-show="finished"
        class="source animate__fadeIn"
        :class="finished ? 'animate__animated' : ''"
      >
        {{ authorName }}
        <span v-show="hasSource">— {{ quoteTranslated.source }}</span>
      </div>
    </div>
    <div class="bottom">
      <div class="buttons">
        <button
          class="button"
          :class="computeClass(true)"
          @click="answer(true)"
          :disabled="finished"
        >
          HITLER
        </button>
        <button
          class="button"
          :class="computeClass(false)"
          @click="answer(false)"
          :disabled="finished"
        >
          {{ $t("not_hitler").toUpperCase() }}
        </button>
      </div>
      <div
        v-show="showFinish"
        class="finish animate__animated animate__fadeInUp"
        id="end"
      >
        <div v-if="autoplay" class="auto-play-container">
          <div>Replay in {{ countAutoplay }}</div>
          <!-- <div>
            <span class="cancel" @click="changeAutoPlayValue" >Cancel auto-play</span>
          </div> -->
        </div>
        <div v-else>
          <div>{{ $t("replay") }}</div>
          <div @click="playAgain">
            <img src="../static/img/Refresh.png" width="50px" />
          </div>
        </div>
        <div class="autoplay">
          Autoplay
          <ToggleButton
            @change="changeAutoPlayValue"
            :value="autoplay"
            :speed="100"
            color="#14AAB2"
            :width="65"
            :height="23"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataHelper from "../tools/dataHelper";
import bus from "../tools/bus";
import { ToggleButton } from "vue-js-toggle-button";

export default {
  data() {
    return {
      quotesHitler: [],
      quotesMisc: [],
      quotesNotHitler: [],
      quote: {
        text: "",
        author: "",
        source: "",
        hitler: false,
      },
      doneQuotes: [],
      finished: false,
      won: false,
      answeredHitler: false,
      showFinish: false,
      autoplay: true,
      countAutoplay: 0,
      autoplayInterval: null,
    };
  },
  created() {
    this.quotesHitler = dataHelper.get("hitler");
    this.quotesMisc = dataHelper.get("misc");
    this.quotesNotHitler = dataHelper.getAll();
    this.playAgain();
    this.initAutoplay();
  },
  computed: {
    hasSource() {
      return this.quote.source !== undefined && this.quote.source.length > 0;
    },
    quoteTranslated() {
      return this.quote[this.$i18n.locale];
    },
    authorName() {
      const quote = this.quote;

      if (this.$te(quote.author)) return this.$t(quote.author);
      if (this.quoteTranslated.author !== undefined)
        return this.quoteTranslated.author;
      return quote.author;
    },
  },
  methods: {
    initAutoplay() {
      const localAutoplay = localStorage.getItem("autoplay");
      if (localAutoplay !== null) {
        this.autoplay = JSON.parse(localAutoplay);
      }
    },
    answer(hitler) {
      this.answeredHitler = hitler;
      this.won = this.quote.hitler === hitler;
      this.finished = true;
      bus.$emit("finish", this.won);
      setTimeout(() => {
        this.showFinish = true;
        if (this.autoplay) this.doAutoPlay();
        setTimeout(() => {
          this.scrollToEnd();
        }, 500);
      }, 1000);
    },
    computeClass(hitler) {
      if (!this.finished) {
        return "default pop " + (hitler ? "" : "pop-delay");
      }
      if (this.won) {
        return this.answeredHitler === hitler
          ? "won animate__animated animate__tada"
          : "gray";
      }
      return this.answeredHitler === hitler
        ? "fail  animate__animated animate__headShake"
        : "won animate__animated animate__shakeY";
    },
    playAgain() {
      this.finished = false;
      this.showFinish = false;
      const isHitler = Math.random() > 0.5;
      if (isHitler) {
        this.quote = this.getRandomItem(this.filterQuotes(this.quotesHitler));
      } else {
        this.quote = this.getRandomQuote();
      }
      this.quote.hitler = isHitler;
      this.doneQuotes.push(this.quote);
    },
    filterQuotes(quotes) {
      var filteredQuotes = quotes.filter(
        (q) =>
          this.doneQuotes.find((dq) => dq.text.localeCompare(q.text) === 0) ===
          undefined
      );
      if (filteredQuotes.length === 0) {
        this.doneQuotes.splice(0); // Clear array
        filteredQuotes = quotes;
      }
      return filteredQuotes;
    },
    getRandomQuote() {
      const storageValue = localStorage.getItem("misc");
      if (storageValue === null) {
        const index = this.getRandomIndex(this.quotesMisc);
        this.saveMiscQuote(index);
        return this.quotesMisc[index];
      }

      const pickedIndexes = JSON.parse(storageValue);
      const availableMiscIndexes = [];
      for (var i = 0; i < this.quotesMisc.length; i++) {
        if (!pickedIndexes.includes(i)) availableMiscIndexes.push(i);
      }

      if (availableMiscIndexes.length === 0)
        return this.getRandomItem(this.filterQuotes(this.quotesNotHitler));

      const index = this.getRandomItem(availableMiscIndexes);
      this.saveMiscQuote(index);
      return this.quotesMisc[index];
    },
    saveMiscQuote(quoteIndex) {
      const storageValue = localStorage.getItem("misc");
      if (storageValue === null) {
        localStorage.setItem("misc", JSON.stringify([quoteIndex]));
      } else {
        const quoteIndexes = JSON.parse(storageValue);
        quoteIndexes.push(quoteIndex);
        localStorage.setItem("misc", JSON.stringify(quoteIndexes));
      }
    },
    getRandomIndex(array) {
      return Math.floor(Math.random() * array.length);
    },
    getRandomItem(array) {
      return array[this.getRandomIndex(array)];
    },
    scrollToEnd() {
      this.$scrollTo("#end", 1000);
    },
    doAutoPlay() {
      this.countAutoplay = 4;
      this.autoplayInterval = setInterval(() => {
        this.countAutoplay--;
      }, 1000);
    },
    onEndAutoplayTimer() {
      clearInterval(this.autoplayInterval);
      this.autoplayInterval = null;
      this.playAgain();
    },
    changeAutoPlayValue() {
      if (this.autoplay) {
        if (this.autoplayInterval !== null) {
          clearInterval(this.autoplayInterval);
          this.autoplayInterval = null;
        }
      } else {
        this.doAutoPlay();
      }
      this.autoplay = !this.autoplay;
      localStorage.setItem("autoplay", this.autoplay)
    },
  },
  watch: {
    countAutoplay(value, __) {
      if (value === 0) this.onEndAutoplayTimer();
    },
  },
  components: {
    ToggleButton,
  },
};
</script>

<style scoped>
.game {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  row-gap: 2rem;
  width: 100%;
  min-width: 100%;
  /* height: 100%; */
  flex: 1;
  justify-content: space-around;
  transition: all 0.2s ease;
}

.quote {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  font-size: 1.5rem;
}

@media screen and (max-width: 500px) {
  .quote {
    font-size: 1.5rem;
  }
}

.source {
  font-size: 1.5rem;
  color: rgb(84, 84, 84);
}

.bottom {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  text-align: center;
  vertical-align: center;
  align-content: center;
  justify-self: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  column-gap: 1rem;

  width: 50%;
  margin: 0 auto;
}

.finish {
  display: flex;
  flex-direction: column;
  font-size: 1.7rem;
  cursor: default;
  row-gap: 1rem;
}

.finish img {
  transition: transform 0.2s ease-in-out;
}

.finish img:hover {
  transform: rotate(360deg);
  cursor: pointer;
}

.button:main,
.button:focus {
  user-select: auto;
}

.button:disabled {
  cursor: auto;
}

.default {
  appearance: button;
  background-color: #1899d6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  /* display: inline-block; */
  font-family: din-round, sans-serif;
  /* font-size: 15px; */
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter 0.2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;

  display: flex;

  text-align: center;
  vertical-align: center;
  align-content: center;
  justify-self: center;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.default:after {
  background-clip: padding-box;
  background-color: #1cb0f6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.default:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.won {
  appearance: button;
  background-color: rgb(15, 156, 15);
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  /* display: inline-block; */
  font-family: din-round, sans-serif;
  /* font-size: 15px; */
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter 0.2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;

  display: flex;

  text-align: center;
  vertical-align: center;
  align-content: center;
  justify-self: center;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.won:after {
  background-clip: padding-box;
  background-color: rgb(17, 176, 17);
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.fail {
  background-color: rgb(197, 2, 2);
  appearance: button;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  /* display: inline-block; */
  font-family: din-round, sans-serif;
  /* font-size: 15px; */
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter 0.2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;

  display: flex;

  text-align: center;
  vertical-align: center;
  align-content: center;
  justify-self: center;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.fail:after {
  background-color: rgb(221, 0, 0);
  background-clip: padding-box;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.gray {
  background-color: gray;
  appearance: button;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  /* display: inline-block; */
  font-family: din-round, sans-serif;
  /* font-size: 15px; */
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter 0.2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;

  display: flex;

  text-align: center;
  vertical-align: center;
  align-content: center;
  justify-self: center;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.gray:after {
  background-color: rgb(146, 146, 146);
  background-clip: padding-box;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

@keyframes pop {
  0% {
    transform: scaleX(0);
  }
  85% {
    transform: scaleX(1.1);
  }
  100% {
    transform: scaleX(1);
  }
}

.pop {
  animation: 0.2s ease pop;
}

.pop-delay {
  animation-delay: 0.25s;
}

.auto-play-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.auto-play-container .cancel {
  font-size: 1rem;
  color: rgb(56, 56, 212);
}

.auto-play-container .cancel:hover {
  cursor: pointer;
  font-weight: 1000;
}

.autoplay {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  text-align: center;
  vertical-align: center;
  align-content: center;
  justify-self: center;
  align-items: center;
  text-align: center;
  justify-content: center;
}
</style>