<template>
  <div class="streak-menu-container">
    <div class="current-streak" v-show="currentStreak > 0">
      <img :src="require('../static/img/Streak' + getStreakImage())" />
      <div class="streak-value">
        {{ currentStreak }}
      </div>
    </div>
    <div class="max-streak" v-show="maxStreak > 0">
      Max streak: {{ maxStreak }}
    </div>
  </div>
</template>

<script>
import bus from "../tools/bus";

export default {
  data() {
    return {
      currentStreak: 0,
      maxStreak: 0,
    };
  },
  created() {
    bus.$on("finish", this.onFinish);
    this.loadLocalStorage();
  },
  methods: {
    loadLocalStorage() {
      {
        const storageValue = localStorage.getItem("streak");
        if (storageValue !== null) {
          this.currentStreak = parseInt(storageValue);
        }
      }
      {
        const storageValue = localStorage.getItem("maxStreak");
        if (storageValue !== null) {
          this.maxStreak = parseInt(storageValue);
        }
      }
    },
    getStreakImage() {
      return this.currentStreak === 0 ? "None.png" : ".gif";
    },
    onFinish(won) {
        if (won) {
            this.currentStreak++
            if (this.currentStreak > this.maxStreak)
                this.maxStreak = this.currentStreak
        } else {
            this.currentStreak = 0
        }
        localStorage.setItem("streak", this.currentStreak)
        localStorage.setItem("maxStreak", this.maxStreak)
    }
  },
};
</script>

<style>
.streak-menu-container {
  font-size: 1rem;
  color: black;
  position: relative;
}

.current-streak img {
  position: relative;
  bottom: 6px;
  right: 2px;
  width: 40px;
}

.streak-value {
  position: absolute;
  top: 41%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-weight: 1000;
  font-size: 1.2rem;
  text-shadow: 0px 0px 5px white;
}

.max-streak {
  font-size: 0.8rem;
}
</style>