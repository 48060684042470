export default {
    "EN": { 
        "who": "Who said:",
        "not_hitler": "Not Hitler",
        "love": "Love each other",
        "reminder": "A reminder that anyone can be fooled by the most hateful people if they are eloquent enough.",
        "replay": "Play again",
        "aristotle": "Aristotle",
        "kant": "Immanuel Kant",
        "plato": "Plato",
        "socrates": "Socrates",
    },
    "FR": {
        "who": "Qui a dit:",
        "not_hitler": "Pas Hitler",
        "love": "Aimez-vous svp",
        "reminder": "Un rappel que tout le monde peut se faire avoir par les personnes les plus haineuses si elles sont suffisamment éloquentes.",
        "replay": "Rejouer",
        "aristotle": "Aristote",
        "kant": "Emmanuel Kant",
        "plato": "Platon",
        "socrates": "Socrate",
    },
    "ES": {
        "who": "Quien dijo:",
        "not_hitler": "Hitler no",
        "love": "Amaos los unos a los otros",
        "reminder": "Un recordatorio de que cualquiera puede ser engañado por las personas más odiosas si son lo suficientemente elocuentes.",
        "replay": "Volver a jugar",
        "aristotle": "Aristóteles",
        "kant": "Immanuel Kant",
        "confucius": "Confucio",
        "plato": "Platón",
        "socrates": "Sócrates",
    },
    "PT": { 
        "who": "Quem disse:",
        "not_hitler": "Não Hitler",
        "love": "Amem-se um ao outro",
        "reminder": "Um lembrete de que qualquer pessoa pode ser enganada pelas pessoas mais odiosas se for suficientemente eloquente.",
        "replay": "Reproduzir novamente",
        "aristotle": "Aristóteles",
        "kant": "Immanuel Kant",
        "confucius": "Confúcio",
        "plato": "Platão",
        "socrates": "Sócrates",
    },
    "DE": { 
        "who": "Wer sagte das",
        "not_hitler": "Nicht Hitler",
        "love": "Einander lieben",
        "reminder": "Eine Erinnerung daran, dass jeder von den hasserfülltesten Menschen getäuscht werden kann, wenn sie wortgewandt genug sind.",
        "replay": "Erneut spielen",
        "aristotle": "Aristoteles",
        "kant": "Immanuel Kant",
        "confucius": "Konfuzius",
        "plato": "Platon",
        "socrates": "Sokrates",
    },
}