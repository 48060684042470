<template>
  <div class="page">
    <Header />
    <Game />
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Game from "./components/Game.vue";
import Footer from "./components/Footer.vue";

export default {
  components: {
    Header,
    Game,
    Footer,
  },
};
</script>

<style>
@import "./styles/main.css";

.page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  transition: all .2s ease;
}
</style>
