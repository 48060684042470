<template>
  <div class="header">
    <div>
      <span class="title red">Hitler</span> or
      <span class="title green">Not</span>?
    </div>
    <Streak/>
  </div>
</template>

<script>
import Streak from "./Streak.vue"
export default {
  components: {
    Streak,
  }
};
</script>

<style scoped>
.header {
  position: relative;
  margin-top: 2rem;
  font-size: 4rem;
  font-family: "Mobilo Medium";
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 500px) {
  .header {
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 450px) {
  .header {
    font-size: 3rem;
  }
}

@media screen and (max-width: 389px) {
  .header {
    font-size: 2.5rem;
  }
}

.header:hover {
  cursor: default;
}

.title {
  transition: all 0.2s ease;
}
.title:hover {
  font-size: 4.3rem;
}
</style>