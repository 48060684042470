<template>
  <VueSelect
    v-model="selected"
    :options="locales"
    label="title"
    :searchable="false"
    class="select"
    :clearable="false"
    @input="onChange"
  >
    <template slot="selected-option" slot-scope="option">
      <flag class="flag" :iso="option.flag.toLowerCase()" />
      {{ option.title }}
    </template>
    <template v-slot:option="option">
      <flag class="flag" :iso="option.flag.toLowerCase()" />
      {{ option.title }}
    </template>
  </VueSelect>
</template>

<script>
import VueSelect from "vue-select";

export default {
  data() {
    return {
      locales: [
        {
          title: "English",
          flag: "US",
          value: "EN",
        },
        {
          title: "Français",
          flag: "FR",
          value: "FR",
        },
        {
          title: "Español",
          flag: "ES",
          value: "ES",
        },
        {
          title: "Português",
          flag: "PT",
          value: "PT",
        },
        {
          title: "Deutsch",
          flag: "DE",
          value: "DE",
        },
      ],
      selected: null,
    };
  },
  created() {
    const storageValue = localStorage.getItem("locale");
    if (storageValue === null) {
      this.$i18n.locale = "EN";
      this.selected = this.locales[0];
      localStorage.setItem("locale", "EN");
    } else {
      this.$i18n.locale = storageValue;
      this.selected = this.locales.find(
        (l) => l.value.localeCompare(storageValue) === 0
      );
    }
  },
  methods: {
    onChange() {
      this.$i18n.locale = this.selected.value;
      localStorage.setItem("locale", this.selected.value);
      this.$emit("change")
    },
  },
  components: {
    VueSelect,
  },
};
</script>

<style>
@import "../styles/vue-select.css";

.select {
  width: 160px;
  margin: 0 auto;
}

.vs__dropdown-option {
  font-size: 1rem !important;
  width: 160px;
}

.vs__selected {
  font-size: 1rem;
}

.vs__dropdown-menu {
  width: 160px !important;
  max-width: 160px !important;
  min-width: 0;
  overflow-x: hidden;
  /* width: 100%; */
}

.flag {
  margin-right: 10px;
}
</style>