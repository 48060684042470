import Vue from 'vue'
import Main from './Main.vue'
import store from './store'
import VueAnime from 'vue-animejs';
import 'animate.css/animate.min.css';
import VueScrollTo from 'vue-scrollto';
import VueI18n from 'vue-i18n'
import i18nKeys from "./static/i18n/keys"
import FlagIcon from 'vue-flag-icon'

Vue.config.productionTip = false
export const bus = new Vue();

Vue.use(VueAnime)
Vue.use(VueScrollTo)

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: "FR",
  messages: i18nKeys,
})

Vue.use(FlagIcon);

new Vue({
  render: h => h(Main),
  store,
  i18n,
}).$mount('#app')
