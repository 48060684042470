import authors from "../static/data/authors"

class DataHelper {
    getAll() {
        const result = []
        for (const key of authors) {
            result.push(this.get(key))
        }
        return result.flat()
    }

    get(name) {
        return require("../static/data/authors/" + name + ".json");
    }
}

export default new DataHelper()